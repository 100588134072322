import React from "react";
import Layout from "./Layout";

interface BasicPageProps {
  children: React.ReactNode;
  title: string;
}
const BasicPage = ({ children, title }: BasicPageProps) => {
  return (
    <Layout>
      <div className="bg-white rounded-xl mt-8 px-16 max-w-screen-lg mx-auto">
        <h1 className="text-4xl font-bold text-neutral-800 pt-8 pb-4">
          {title}
        </h1>
        {children}
      </div>
    </Layout>
  );
};

export default BasicPage;
