import { HeadProps, Link } from "gatsby";
import React from "react";
import BasicPage from "../components/BasicPage";
import Seo from "../features/seo/Seo";

const Privacy = () => {
  return (
    <BasicPage title="Privacy Policy">
      <div className="prose prose-neutral prose-ol:list-none max-w-none">
        <p>
          <small>Last updated: 11 August 2014</small>
        </p>
        <p>
          We value your privacy and strive to protect your personal information.
          Please read this Policy to understand what types of information we
          collect from you, for what purposes and what choices you have
          regarding our collection of your information.
        </p>
        <p>
          This policy covers the Giancolianswers.com website and all
          *.giancolianswers.com subsites (collectively, Giancoli Answers). By
          access, using or posting information to Giancoli Answers, you agree to
          this Privacy Policy and the Terms of Service{" "}
          <Link to="/tos">tos</Link>{" "}
        </p>
        <h2>Children's Personal Information</h2>
        <p>
          This Website is not intended for children under 13 years of age. We do
          not knowingly collect personal information from children under 13
          without prior verifiable parental consent. If we learn that a child
          under the age of 13 has submitted personally identifiable information
          online without parental consent, we will take all reasonable measures
          to delete such information from our databases and to not use such
          information for any purpose (except where necessary to protect the
          safety of the child or others).
        </p>
        <p>
          If you believe that a child under the age of 13 has provided us with
          personal information without verification of parental consent, please
          contact us at{" "}
          <a href="mailto:learn@giancolianswers.com">
            learn@giancolianswers.com
          </a>
          .
        </p>
        <h2>Information we collect about you</h2>
        <p>
          We collect several types of information from and about you, including:
        </p>
        <ol>
          <li>
            Your email address and password. We treat this information as
            "Personally Identifiable Information" or "PII". We never store
            passwords in plain text format, only secure password hashes.
          </li>
          <li>
            If you made a purchase on Giancoli Answers, we store a token
            provided by our payment processor{" "}
            <a href="https://stripe.com">Stripe</a> which represents your credit
            card information. Aside from this token, which is usable only by
            Giancoli Answers, credit card information is not stored on Giancoli
            Answers servers.
          </li>
          <li>
            Non-personally identifiable information, such as demographic
            information about you, information about your computer system or
            device, your preferences, your school, your online activity, and
            your location information ("Non-Personally Identifiable Information"
            a "Non-PII"). Non-PII, by itself, does not identify you, but it can
            be combined with other information in way that allows you to be
            identified. If this happens, we will treat the combined information
            as PII.
          </li>
          <li>
            Like most websites, including{" "}
            <a href="http://www.cbc.ca/news/technology/yahoo-stops-honouring-do-not-track-setting-1.2629950">
              Google and Yahoo
            </a>
            , Giancoli Answers ignores the web browser Do Not Track settings.
            Activity is tracked regardless, but still in accordance with the
            Google Analytics privacy policy.
          </li>
          <li>
            As a Member or Guest, you may access and update your personal
            information as described in the Terms of Use, however, information
            originally submitted to Giancoli Answers by you may be retained by
            Giancoli Answers indefinitely for technical, legal or other reasons.
          </li>
        </ol>
        <p>
          We may collect information from or about you in the following ways:
        </p>
        <ul>
          <li>
            Information Provided by You. We collect information provided by you
            when you (1) create your public profile; (2) communicate with us or
            request information about or from us by e-mail or other means; (3)
            participate in our online forums or post content on this Website
            ("User Contributions"), (4) fill out forms or fields on this
            Website; (5) sign-up for any of our newsletters, materials or our
            services on this Website or other sites; or (6) participate in our
            online surveys or questionnaires.
          </li>
          <li>
            Information from Other Sources. We may collect information about you
            from other third party or public sources, such as from public
            forums, social networks (i.e., Facebook, LinkedIn, Twitter, or
            others), blogs, other users, or our business partners.
          </li>
          <li>
            Automatic Information Collection. We also use automatic data
            collection technologies to collect and store certain information
            about your equipment, browsing actions and patterns when you
            interact with this Website through your computer or mobile device.
            In addition, we may allow third party ad networks to use automatic
            data collection technologies to collect similar information about
            you for purposes of providing interest-based ads.
          </li>
        </ul>
        <h2>Automatic information collection technologies</h2>
        <p>
          The information that we collect about your equipment, browsing actions
          and patterns includes, but is not limited to, traffic data, location
          data, logs, the resources that you access, search queries, as well as
          information about the computer or device you are using and the
          Internet connection, including your IP address, operating system and
          browser type.
        </p>
        <p>
          This automatically collected information typically does not include
          PII, but we may maintain it or associate it with your personal
          information collected in other ways. Collection of this type of
          information helps us to improve this Website and to deliver a better
          and more personalized service by enabling us to, among other things:
          (1) estimate our audience size and usage patterns; (2) store
          information about your preferences, allowing us to customize this
          Website according to your individual interests; (3) speed up your
          searches; and (4) recognize you when you return to this Website.
        </p>
        <p>
          The automatic collection technologies we or our service providers use
          for this automatic information collection may include:
        </p>
        <ul>
          <li>
            Cookies (or browser cookies). This Website may use two types of
            cookies (small data files placed on the hard drive of your computer
            when you visit a website): a "session cookie", which expires
            immediately when you end your browsing session and a "persistent
            cookie", which stores information on your hard drive so when you end
            your browsing session and return to this website later, the cookie
            information is still available.
          </li>
          <li>
            Web Beacons. Pages of this Website and any e-mails sent to you may
            contain small electronic files known as web beacons (also referred
            to as clear gifs, pixel tags, and single-pixel gifs) that permit us,
            for example, to count users who have visited those pages or opened
            our e-mails.
          </li>
        </ul>
        <h2>Third party advertising partners and interest-based ads</h2>
        <p>
          We use third party ad networks to display advertisements on this
          Websites. These third parties also may use cookies, web beacons and
          other automatic collection technologies to collect information about
          you when you visit this Websites for purposes of determining your
          preferences in order to deliver interest-based advertising and other
          targeted content to you.
        </p>
        <p>
          We do not provide any PII to these third party advertising partners,
          but they may combine the non-PII collected on the Website with PII
          they collect directly from you or receive from other sources. We do
          not have access to or control over the automatic collection
          technologies that these third party advertisers or any third party
          websites may use, and the information practices of these third party
          advertisers and third party websites are subject to these parties'
          respective privacy policies, not this Privacy Policy.
        </p>
        <p>
          Google Analytics data is used for remarketing purposes. You may visit{" "}
          <a href="https://www.google.com/settings/ads">ad settings</a> to
          opt-out of Google Analytics for Display Advertising and customize
          Google Display Network ads.
        </p>
        <h2>Links to Third Party Websites and Social Media Widgets</h2>
        <p>
          This Website and some of our electronic communications to you, may
          contain links to other websites that are owned and operated by third
          parties. Links to third parties from this Website are not an
          endorsement by us. We do not control, and are not responsible for, the
          privacy and security practices of these third parties. We recommend
          that you review the privacy and security policies of these third
          parties to determine how they handle information they may collect from
          or about you.
        </p>
        <p>
          This Website may also include social media features, such as the
          Facebook Like button, Google Plus, and Twitter widgets. These features
          may collect information about your IP address and the page you are
          visiting on this Website, and they may set a cookie to make sure the
          feature functions properly. Your interactions with these features and
          the information from or about you collected by them are governed by
          the privacy policies of the companies that provide them.
        </p>
        <h2>How We Use Your Information</h2>
        <p>We use your information, including any PII, to:</p>
        <ul>
          <li>Provide information and services requested by you;</li>
          <li>
            Provide customer support, including responding to your requests and
            questions and troubleshooting and resolving problems or complaints;
          </li>
          <li>Verify the information you provide to us;</li>
          <li>Communicate with you;</li>
          <li>
            Understand and anticipate your use of or interest in, our services,
            and content, and the products, services, and content offered by
            others;
          </li>
          <li>
            Develop and display products, services, and content tailored to your
            interests on our websites and other websites;
          </li>
          <li>
            Provide you with promotional materials and Newsletters in case you
            opt-in to receive those;
          </li>
          <li>
            Measure the overall effectiveness of our online, content, and
            programming, and other activities;
          </li>
          <li>Manage our business and operations;</li>
          <li>Protect the security and integrity of this Website;</li>
          <li>
            Carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us;
          </li>
          <li>
            Use or post user contributions as permitted in our Terms of Service;
            and
          </li>
          <li>
            Fulfill any other purposes for which you provide your information
            and for any other purpose as described to you at the time your
            information is collected or for which your consent is given.
          </li>
        </ul>
        <h2>Disclosure of your information</h2>
        <p>
          We may disclose and share aggregated non-PII about you at our
          discretioon. We may disclose or share your PII only in limited
          circumstances:
        </p>
        <ul>
          <li>
            With any Drupal Association employee or agent for support of our
            internal and business operations or to respond to a request made by
            you.
          </li>
          <li>
            We may disclose information we collect from or about you when we
            believe disclosure is appropriate to comply with the law, to enforce
            agreements, or to protect the rights, property, or safety of our
            company, our affiliates, users of this Website, or other persons.
          </li>
          <li>
            If some or all of our business assets are sold or transferred as a
            result of any corporate change (merger, consolidation,
            reorganization, bankruptcy, etc.), we may transfer the corresponding
            information regarding our customers and users of this Website,
            including PII. We also may retain a copy of such information.
            Nothing in this Privacy Policy is intended to interfere with our
            ability to transfer all or part of our business, equity interests,
            or assets (including this Website) to an affiliate or unaffiliated
            third party at any time, for any purpose, without any limitation,
            and without notice or any compensation to you.
          </li>
        </ul>
        <h2>Your choices about use and disclosure of your information</h2>
        <p>
          We strive to provide you with choices regarding our use of your
          personal information. Below are some mechanisms that provide you with
          control over your information:
        </p>
        <ul>
          <li>
            Promotional and Informational e-mails. We do not send any
            promotional or informational emails without your opt-in first. If
            you do not wish to receive promotional e-mails from us, follow the
            unsubscribe process at the bottom of the promotional e-mail. Note
            that even if you opt-out, you may still receive transactional
            e-mails from us (e.g., e-mails related to the completion of your
            registration, correction of user data, password reset requests,
            reminder e-mails that you have requested, and any other similar
            communications essential to your transactions on this Website).
          </li>
          <li>
            Automatic Information Collection Technologies and Advertising. The
            "help" function of your browser should contain instructions on how
            to set your browser to not accept new cookies, to notify you when a
            cookie is issued, or how to disable cookies altogether. If you
            disable or refuse cookies, please note that some parts of this
            Website may be inaccessible or not function properly.
          </li>
        </ul>
        <h2>Accessing and correcting your information</h2>
        <p>
          We cannot delete your personal information except by also deleting
          your account. We also may not accommodate a request to change or
          delete information if we believe the change would violate any law or
          legal requirements, be contrary to our Terms of Service or any other
          applicable agreement between you and us, or cause the information to
          be incorrect.
        </p>
        <p>
          Upon deletion all private and personally identifying information from
          your profile will be deleted. The data will stay in backups on our
          servers for 2 weeks, after which it will be completely removed. Public
          content you created, such as issues, forum posts, projects,
          documentation page revisions, etc. won’t be deleted. All this content
          will be attributed to ‘Anonymous’ user.
        </p>
        <p>Once deleted, your account is gone and can not be restored.</p>
        <h2>Protection of your information</h2>
        <p>
          We use reasonable security measures to protect your information
          collected through this Website. We do not store passwords in plain
          text format, only secure password hashes. However, no method of
          transmission or electronic storage is 100% safe, and we cannot
          guarantee absolute security. Therefore, your use of this Website is at
          your own risk and we do not promise or guarantee, and you should not
          expect, that your information will always and absolutely remain
          private and secure. We are not responsible for the circumvention of
          any privacy settings or security measures contained on or concerning
          this Website. You are also responsible for taking reasonable steps to
          protect your personal information against unauthorized disclosure or
          misuse.
        </p>
        <h2>Visiting this website from outside the United States</h2>
        <p>
          If you are visiting this Website from outside the United States,
          please be aware that your information may be transferred to, stored,
          and processed in the United States where our servers are located and
          our central database is operated. The information protection laws of
          the United States might not be as comprehensive or protective as those
          in your country. By using this Website and our services, you
          understand that your information may be transferred to our facilities
          and to third parties as described in this Privacy Policy.
        </p>
        <h2>Changes to this privacy policy</h2>
        <p>
          We may update or amend this Privacy Policy at any time. This Privacy
          Policy will reflect the date it was last updated or amended. If we
          make any material amendments, we will notify you by posting a notice
          of such amendments on this Website. All amendments will take effect
          immediately upon our posting of the updated Privacy Policy on this
          Website. Your continued use of this Website will indicate your
          acceptance of the changes to the Privacy Policy.
        </p>
        <h2>Contacting us</h2>
        <p>
          If you have questions or concerns about this Privacy Policy, our
          information practices, or wish to make a request regarding your
          information, please contact us at{" "}
          <a href="mailto:learn@giancolianswers.com">
            learn@giancolianswers.com
          </a>
          .
        </p>
      </div>
    </BasicPage>
  );
};

export default Privacy;

export const Head = (props: HeadProps) => {
  const title = "Privacy policy | Giancoli Answers";

  return <Seo title={title} pathname={props.location.pathname} />;
};
